import React, { useEffect, useState } from "react";
import TextAreaComponents from "./../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";

import axios from "axios";
import dayjs from "dayjs";
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
//radio button imports end

import "./purchaseOrder.css";
import {
  GetEmployeeAPi,
  GetItem,
  GetSupplierAPi,
  PostPurchaseOrderAPi,
} from "../../../api/screenApi/purchaseOrder/purchaseOrderApi";
import cancel from "./../../../assests/images/close.png";
import info from "./../../../assests/images/information-button.png";
import { RoundedCorner } from "@mui/icons-material";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable.jsx";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

function PurchaseOrder(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);
  const [Search, setSearch] = useState();
  const [SupplierClear, setSupplierClear] = useState(false);
  const [ItemClear, setItemClear] = useState(false);
  const [Flag, setFlag] = useState(false);
  //Purchase order entry states start
  const [PurchaseOrderType, setPurchaseOrderType] = useState();
  const [PurchaseOrderNumber, setPurchaseOrderNumber] = useState();
  const [DateTime, setDateTime] = useState(dayjs());
  const [Remarks, setRemarks] = useState();
  //supplier states
  const [SupplierList, setSupplierList] = useState([]);
  const [SelectedSupplier, setSelectedSupplier] = useState();
  //employee states
  const [EmployeeList, setEmployeeList] = useState([]);
  const [SelectedEmployee, setSelectedEmployee] = useState();
  //item list
  const [ItemList, setItemList] = useState([]);
  const [SelectedItem, setSelectedItem] = useState();

  const [tableLoop, setTableLoop] = useState(5);

  //order details
  const [OrederDetails, setOrderDetails] = useState([]);
  const [OrderDetailsRemarks, setOrderDetailsRemarks] = useState("");
  const [RequestedQty, setRequestedQty] = useState(0);

  //Purchase order entry states end

  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end

  const GetEmployeeList = async () => {
    var list = [];
    var res = await GetEmployeeAPi();

    res.map((d, i) => {
      var obj = { id: d.employeeId, title: d.firstName + " " + d.middleName };
      list.push(obj);
    });
    setEmployeeList(list);
  };

  const GetSupplier = async () => {
    var list = [];
    var res = await GetSupplierAPi();
    // res.map((d, i) => {
    //   var obj = { id: d.id, title: d.supplierName };
    //   list.push(obj);
    // });
    setSupplierList(res);
  };

  const GetItemList = async () => {
    var res = await GetItem();

    setItemList(res);
  };

  useEffect(() => {
    GetSupplier();
    GetEmployeeList();
    GetItemList();
  }, []);

  const [purchaseOrder, setPurchaseOrder] = useState({
    CounterId: 1,
    CounderName: "Counter1",
    CreationDate: "2023-10-03T18:00:00.000Z",
    CreatorId: 1,
    EmployeeName: "Employee Name",
    IsVoid: false,
    Number: "123",
    OrderDate: DateTime,
    PurchaseOrderTypeId: "1",
    Remarks: Remarks,
    RequestedByEmployeeId: 29,
    SupplierId: 4,
    SupplierName: "Miage Motors",
    UpdateDate: "2023-10-03T18:00:00.000Z",
    UpdatorId: 1,
  });

  const purchaseOrderTypeRadio = (event) => {
    setPurchaseOrderType(event.target.value);
  };

  const initialValue = [
    { title: "Monty Python and the Holy Grail", year: 1975 },
  ];

  //table start
  const columns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "orderNo", headerName: "Order No", flex: 1 },
    {
      field: "orderDate",
      headerName: "Order Date",
      renderCell: (params) => <span>{DateFormater(params.row.orderDate)}</span>,
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",

      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/purchase-order-report/" + params.row.orderNo,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [rows, setrows] = React.useState([]);

  const [isLoading, SetisLoading] = React.useState(false);

  const [pageState, setpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });
  const OrderListPaged = async (props, from) => {
    if (from != "Search") {
      setpageState((old) => ({ ...old, isLoading: true }));

      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseOrder/GetPurchaseOrderPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          //purchase order number generator start

          var entryNo = EntryNumberGenarator(
            response.data._purchaseOrders[0].number,
            "INV1-PO-"
          );
          setPurchaseOrderNumber(entryNo);

          //purchase order number genarator end
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseOrders.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              orderNo: d.number,
              orderDate: d.orderDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setpageState((old) => ({ ...old, isLoading: true }));

      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseOrder/GetPurchaseOrderPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          //purchase order number generator start
          const myArray = response.data._purchaseOrders[0].number.split("-");
          let lastorderNumber = myArray[2];
          let num = parseInt(lastorderNumber) + 1;
          var newOrderNumberString = num.toString();
          while (newOrderNumberString.length < 11) {
            newOrderNumberString = "0" + newOrderNumberString;
          }
          var finalString = "INV1-PO-" + newOrderNumberString;
          setPurchaseOrderNumber(finalString);

          //purchase order number genarator end
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseOrders.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              orderNo: d.number,
              orderDate: d.orderDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    }
  };
  const onSearch = async () => {
    await OrderListPaged("props", "Search");
  };
  const onClear = async () => {
    var props = {
      page: 0,
      pageSize: 10,
    };
    setSearch("");
    await OrderListPaged(props, "Main");
  };

  const GetPurchaseOrderNoAPi = async (props) => {
    await OrderListPaged(props, "Main");
  };

  //table end

  const handleRequestedBy = (e) => {
    setSelectedEmployee(e);
  };

  const handleSelectedItem = (data) => {
    setSelectedItem(data);
  };
  const handleSupplierChange = (data) => {
    setSelectedSupplier(data);
  };
  const purchaseOrderAdd = () => {
    var valid = true;
    if (!!SelectedItem == false) {
      valid = false;
      errorToast("No item selected");
    } else {
      if (OrederDetails?.find((x) => x.ItemId == SelectedItem.id)) {
        valid = false;
        errorToast("Item already selected");
      }
    }
    if (valid) {
      var list = [...OrederDetails];
      var orderDetailsObj = {
        id: OrederDetails.length + 1,
        // serial:OrederDetails.length+1,
        IsVoid: false,
        ItemId: SelectedItem.id,
        ProductName: SelectedItem.productName,
        PurchaseOrderNumber: "123",
        Remarks: OrderDetailsRemarks,
        RequestedQty: RequestedQty,
        combination: SelectedItem.combination,
      };
      list.push(orderDetailsObj);
      setOrderDetails(list);
      setSelectedItem(null);
      setItemClear(true);
      setFlag(!Flag);
    }
  };

  //selected item table start

  const OrderListcolumns = [
    // { field: "serial", headerName: "Serial", flex: 1 },

    {
      field: "combination",
      headerName: "ProductName",

      flex: 1,
    },

    {
      field: "RequestedQty",
      headerName: "PO.Quantity",
      flex: 1,
      renderCell: (params) => (
        <div>
          <TextAreaComponents
            id={2}
            value={(e) => {
              params.row.RequestedQty = parseInt(e);
            }}
            // label={"Requested Qty"}
            // placeholder={"Requested Qty"}
          />
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      flex: 1,
      renderCell: (params) => (
        <TextAreaComponents
          id={6}
          value={(e) => (params.row.Remarks = e)}
          // label={"Remarks"}
          // placeholder={"Remarks"}
        />
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div
          className="d-flex icon gap-2 mt-2"
          onClick={() => removeItem(params.row)}
        >
          <img src={cancel} alt="img" />
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const removeItem = (data) => {
    var list = [...OrederDetails];
    var obj = OrederDetails.find((x) => x.id == data.id);
    var res = OrederDetails.indexOf(obj);
    list.splice(res, 1);
    setOrderDetails(list);
  };

  //selected item table end

  const onSave = async () => {
    setAlertOpen(false);
    var valid = true;

    //validation start
    if (!!Remarks == false) {
      valid = false;
      errorToast("Remarks is required");
    }
    if (!!DateTime == false) {
      valid = false;
      errorToast("Date not selected");
    }
    if (!!SelectedEmployee == false) {
      valid = false;
      errorToast("Employee not selected");
    }
    if (!!SelectedSupplier == false) {
      valid = false;
      errorToast("Supplier not selected");
    }
    if (OrederDetails.length == 0) {
      valid = false;
      errorToast("No item selected");
    }
    if (OrederDetails.length > 0) {
      var res = OrederDetails.find((x) => x.RequestedQty == 0);
      if (!!res == true) {
        valid = false;
        errorToast("Quantity can't be empty");
      }
    }
    //validation  end

    if (valid) {
      purchaseOrder.Remarks = Remarks;
      purchaseOrder.OrderDate = DateTime;
      purchaseOrder.EmployeeName = SelectedEmployee.title;
      purchaseOrder.RequestedByEmployeeId = SelectedEmployee.id;
      purchaseOrder.SupplierId = SelectedSupplier.id;
      purchaseOrder.SupplierName = SelectedSupplier.supplierName;

      var PurchaseOrderView = {
        purchaseOrder: purchaseOrder,
        purchaseOrderdetailsList: OrederDetails,
      };

      var url = apiAddress + "/api/PurchaseOrder";
      await axios.post(url, PurchaseOrderView).then((response) => {
        setSelectedEmployee(null);
        setSelectedSupplier(null);
        setSupplierClear(true);
        setRemarks("");
        setDateTime(null);
        setOrderDetails([]);
        successToast("Saved successfully");

        var props = {
          page: 0,
          pageSize: 10,
        };

        OrderListPaged(props, "Main");
      });
    }
  };

  const handleClear = () => {
    setSelectedEmployee(null);
    setSelectedSupplier(null);
    setSupplierClear(true);
    setItemClear(true);
    setRemarks("");
    setDateTime(null);
    setOrderDetails([]);
    setFlag(!Flag);
  };

  useEffect(() => {
    setItemClear(false);
  }, [Flag]);

  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <h5>Purchase Order Entry</h5>
      <div className="card card-shadow">
        <div className="row p-3">
          <div
            className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-3 PurchaseOrderCard"
            style={{ marginTop: "0.6rem" }}
          >
            <TextAreaComponents
              id={5}
              value={(e) => setPurchaseOrderNumber(e)}
              label={"Purchase order number"}
              val={PurchaseOrderNumber}
              isactive={true}
            />
            <CustomDateTimePicker
              value={(e) => setDateTime(e)}
              defaultValue={DateTime}
              isDisabled={false}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-1">
            {/* <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Purchase Order Type
              </FormLabel>
              <RadioGroup
                row
                defaultValue={"withoutsalesorder"}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={purchaseOrderTypeRadio}
              >
                <FormControlLabel
                  value="withoutsalesorder"
                  control={<Radio />}
                  label="Without sales order"
                />
              </RadioGroup>
            </FormControl> */}
            <div className="mt-2">
              <AutoCompleteMultiSelect
                datas={SupplierList}
                id={1}
                getOptionLabel={(option) => option.supplierName}
                value={handleSupplierChange}
                label={"Supplier Name"}
                placeholder={"--Supplier name--"}
                clear={SupplierClear}
              />
            </div>
            <div className="mt-3">
              <AutoCompleteMultiSelect
                datas={EmployeeList}
                value={handleRequestedBy}
                id={1}
                label={"Requested by"}
                getOptionLabel={(option) => option.title}
                placeholder={"Resquested by"}
                clear={SupplierClear}
              />
            </div>
          </div>
          <div className="mt-2">
            <TextAreaComponents
              id={6}
              value={(e) => setRemarks(e)}
              label={"Remarks"}
              val={Remarks}
            />
          </div>
          {/* {PurchaseOrderType == "withoutsalesorder" && ( */}
          <div className="mt-2 d-flex gap-2">
            <div className="col-10">
              <AutoCompleteMultiSelect
                datas={ItemList}
                getOptionLabel={(option) => option.combination}
                id={1}
                value={handleSelectedItem}
                label={"Choose item no"}
                placeholder={"--Choose no--"}
                clear={ItemClear}
              />
            </div>
            <div className="col-2">
              <button className="btn-yes btn-common" onClick={purchaseOrderAdd}>
                Add
              </button>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>

      {/* <table class="table table-striped ">
          <thead
            style={{
              backgroundColor: "#2d358f",
              color: "white",
              border: RoundedCorner,
            }}
          >
            <tr>
              <th>Serial No</th>
             
              <th>Item Name</th>
              <th>PO. quentity</th>
              <th>Remarks</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
         
            {OrederDetails.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                
                  <td>{d.combination}</td>
                  <td>
                    <TextAreaComponents
                      id={2}
                      value={(e) => (d.RequestedQty = e)}
                      label={"Requested Qty"}
                      placeholder={"Requested Qty"}
                    />
                  </td>
                  <td>
                    <TextAreaComponents
                      id={6}
                      value={(e) => (d.Remarks = e)}
                      label={"Remarks"}
                      placeholder={"Remarks"}
                    />
                  </td>
                  <td>
                    <div className="d-flex icon gap-2 mt-2">
                      <img src={cancel} alt="img" />
                      <img src={info} alt="img" />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}

      {OrederDetails.length > 0 ? (
        <div className="card card-shadow p-3 mt-3">
          <div>
            <h6>Selected Item</h6>
          </div>
          <ClientSiteDataTable
            dataRows={OrederDetails}
            columns={OrderListcolumns}
            // isActive={true}
            // getselectedData={getSelectedItems}
          />
        </div>
      ) : (
        ""
      )}

      <div className="row mt-3">
        <div className="col-6"></div>
        <div className="col-6">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common" onClick={handleClear}>
              Clear
            </button>
            <button className="btn-Review btn-common">Review</button>
          </div>
        </div>
      </div>
      <div>
        <div className="card card-shadow p-3 mt-3">
          <div>
            <h6>Purchase Order List</h6>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
              <TextAreaComponents
                id={5}
                value={(props) => setSearch(props)}
                label={"Search"}
                val={Search}
              />
            </div>
            <div className="col-12 col-md-12 col-lg-6  gap-2 d-flex ">
              <button className="btn-search btn-common" onClick={onSearch}>
                Search
              </button>
              <button className="btn-search btn-common" onClick={onClear}>
                Clear
              </button>
            </div>
          </div>
          <div className="mt-3">
            <DataTable
              pageState={pageState}
              GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
              isLoading={isLoading}
              rows={rows}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrder;
