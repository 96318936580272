import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import LoginPageLogo from "./../../../assests/images/LoginPageLogo.png";
import LoginPageLogo2 from "./../../../assests/images/payment-method.png";

function PurchaseBillReport(props) {
   {/* const solutions = ["A And H Solutions", "Others"]; */}

   const solutions = ["A And H Solutions", "ABC Company"];
  const [selectedSolution, setSelectedSolution] = useState(solutions[0]);

  const solutionDetails = {
    "aandhsolutions": {
      img: LoginPageLogo,
      phone: "+8801600360735 / +8801326796817",
      email: "ahmedandhasangroup@gmail.com",
      address: "Bangla Bazar, Dhaka 1100",
    },
    "abccompany": {
      img: LoginPageLogo2,
      phone: "+880123456789",
      email: "abc@gmail.com",
      address: "abc Address, abc 1200",
    },
   
  };

  const handleChange = (e) => {
    setSelectedSolution(e.target.value);
  };

  const currentDetails = solutionDetails[selectedSolution.toLowerCase().replace(/\s+/g, "")];
   
  const apiAddress = process.env.REACT_APP_SECRET;
  const componentRef = useRef();
  let { number } = useParams();

  const [receiveDetails, setReceiveDetails] = useState({});
  const [receiveItemDetails, setreceiveItemDetails] = useState([]);
  console.log(number);
  useEffect(() => {
    axios
      .get(apiAddress + "/api/PurchaseBill/GetPurchaseBillByNumber/" + number)
      .then((res) => {
        console.log(res.data);
        setReceiveDetails(res.data.purchaseBill);
        setreceiveItemDetails(res.data.purchaseBillDetailsList);
      });
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  function calculateSum(data, key) {
    return data.reduce((sum, item) => sum + parseFloat(item[key] || 0), 0);
  }

  return (
    <div className="p-3">
      <button className="btn btn-success mt-2 " onClick={handlePrint}>
        Print
      </button>
      <hr />
      <div ref={componentRef}>
      <div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ flexDirection: "column" }}
        >
          <img
            src={currentDetails.img}
            alt=""
            style={{
              width: "8rem",
              borderRadius: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          />

          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <div style={{ margin: 0, fontSize: "25px", fontWeight: "bold" }}>
              <select
                id="solutionsDropdown"
                value={selectedSolution}
                onChange={handleChange}
                style={{
                  fontSize: "20px",
                  padding: "5px 10px",
                  backgroundColor: "transparent",
                  color: "#000",
                  outline: "none",
                  appearance: "none",
                  textAlign: "center",
                  textAlignLast: "center",
                  cursor: "pointer",
                }}
              >
                {solutions.map((solution, index) => (
                  <option key={index} value={solution}>
                    {solution}
                  </option>
                ))}
              </select>
            </div>

            <p style={{ margin: 0 }}>
              <strong>Phone No:</strong> {currentDetails.phone}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Email:</strong> {currentDetails.email}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Address:</strong> {currentDetails.address}
            </p>
          </div>
        </div>




          <h5 style={{ textDecoration: "underline", fontWeight: "bold" }}>
            Purchase Bill Report
          </h5>
        </div>
        <div className="d-flex justify-content-between">
          {receiveDetails != null ? (
            <>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Bill Number: {receiveDetails.number}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Bill Date: {DateFormater(receiveDetails.billDate)}
                </p>

                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Manula Bill: {receiveDetails.manualBillNo}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Remarks: {receiveDetails.remarks}
                </p>
              </div>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Additional Discount: {receiveDetails.addtionalDiscount}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  AIT: {receiveDetails.ait}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Total: {receiveDetails.finalAmount}
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <table className="table table-bordered mt-3">
            <thead>
              <th className="text-center">S/N</th>
              <th className="text-center">Item Id</th>
              <th className="text-center">Item Name</th>
              <th className="text-center">Qty</th>
              <th className="text-center">Base Price</th>
              <th className="text-center">Discount</th>
              <th className="text-center">Vat</th>
              <th className="text-center">SD</th>
              <th className="text-center">Total</th>
            </thead>
            <tbody>
              {receiveItemDetails.length > 0
                ? receiveItemDetails.map((dt, i) => {
                    return (
                      <tr>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{dt.itemId}</td>
                        <td className="text-center">{dt.itemName}</td>
                        <td className="text-center">{dt.qty}</td>
                        <td className="text-center">{dt.unitPrice}</td>
                        <td className="text-center">{dt.discount}</td>
                        <td className="text-center">{dt.vat}</td>
                        <td className="text-center">{dt.sd}</td>
                        <td className="text-center">{dt.amount}</td>
                      </tr>
                    );
                  })
                : ""}
              <tr>
                <td colSpan={3} className="text-end">
                  Total:
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "qty")}
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "unitPrice")}
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "discount")}
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "vat")}
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "sd")}
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "amount")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PurchaseBillReport;
