import * as React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { TextField } from "@mui/material";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function CustomDateTimePicker(props) {
  const [selectedDate, setSelectedDate] = React.useState(
    props.val ? dayjs(props.val) : null
  );

  const handleChange = (newDate) => {
    if (newDate) {
      const localDate = newDate.format("YYYY-MM-DDTHH:mm:ss");
      console.log("Selected Local Date:", localDate);
      setSelectedDate(newDate);
      props.value(localDate);
    }
  };
  React.useEffect(() => {
    console.log("props.val updated:", props.val);
    setSelectedDate(props.val ? dayjs(props.val) : null);
  }, [props.val]);

  return (
    <div className="datetimePicker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DesktopDateTimePicker"]}>
          <DemoItem>
            <DesktopDateTimePicker
              value={selectedDate}
              onChange={handleChange}
              label={props.label}
              disabled={props.isDisabled}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}

// import * as React from "react";
// import dayjs from "dayjs";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
// import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
// import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
// import { TextField } from "@mui/material";

// export default function CustomDateTimePicker(props) {
//   return (
//     <div className="datetimePicker">
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DemoContainer
//           components={[
//             "DateTimePicker",
//             "MobileDateTimePicker",
//             "DesktopDateTimePicker",
//             "StaticDateTimePicker",
//           ]}
//         >
//           <DemoItem>
//             <DesktopDateTimePicker
//               defaultValue={props.defaultValue}
//               disabled={props.isDisabled}
//               label={props.label}
//               value={props.val}
//               onChange={(e) => {
//                 props.value(e);
//               }}
//               slotProps={{ textField: { size: "small" } }}
//             />
//           </DemoItem>
//         </DemoContainer>
//       </LocalizationProvider>
//     </div>
//   );
// }
