import React, { useEffect } from "react";
import billImg from "./../../assests/images/bill.png";
import cancelation from "./../../assests/images/cancelation.png";
import returnImg from "./../../assests/images/return.png";
import orderImg from "./../../assests/images/checklist.png";
import ackImg from "./../../assests/images/acknowledgement.png";
import stimg from "./../../assests/images/stock.png";
import { getAPI } from "../../api/screenApi/ScreenApi";
import backimg from "./../../assests/images/back.png";

import SalesOrder from "./salesOrder/SalesOrder";
import SalesAcknowledgement from "./salesAcknowledgement/SalesAcknowledgement";
import SalesBill from "./salesBill/SalesBill";
import SalesBillCancelation from "./salesBillcancelation/SalesBillCancelation";
import SalesReturn from "./salesReturn/SalesReturn";
import SalesOrderCancelation from "./salesOrderCancelation/SalesOrderCancel";

function Sales() {
  const [menuSelection, setMenuSelection] = React.useState(0);
  const [screenDetails, setScreenDetails] = React.useState(0);
  const [toggleSideBar, setToggleSideBar] = React.useState(false);
  const changeMenu = (props) => {
    setMenuSelection(props);
  };
  useEffect(() => {
    var dt = getAPI(1);
    console.log(dt);
    setScreenDetails(dt);
  }, []);
  return (
    // <div>
    //   <div className="d-flex mt-2">
    //     <div className="" style={{ position: "relative" }}>
    //       <div
    //         className={` backImgDiv ${
    //           toggleSideBar ? "backImgDivOpen" : "backImgDivClose"
    //         } `}
    //         style={{ transition: "all .5s" }}
    //         onClick={() => {
    //           setToggleSideBar(!toggleSideBar);
    //         }}
    //       >
    //         <img src={backimg} alt="" />
    //       </div>

    //       <div
    //         className={`scrollable-div ${
    //           toggleSideBar ? "sideBarOff" : "sideBarOn"
    //         } `}
    //         style={{ transition: "width .5s" }}
    //       >
    //         <h6 className=" ms-3 mt-2" style={{ fontSize: "13px" }}>
    //           <b>Sales</b>
    //         </h6>
    //         <hr className="" style={{ margin: ".5rem" }} />

    //         <div
    //           className={`menuItem ${menuSelection == 0 ? "active" : ""}`}
    //           style={{
    //             width: "100%",
    //             height: "2.5rem",
    //             transition: "background-color 0.3s",
    //           }}
    //           onClick={() => changeMenu(0)}
    //         >
    //           <div
    //             className=" d-flex justify-content-start align-items-center ms-4"
    //             style={{ height: "100%", gap: "10px" }}
    //           >
    //             <img
    //               src={orderImg}
    //               alt="leo"
    //               style={{ width: "1.2rem", height: "1.2rem" }}
    //             />
    //             <p
    //               style={{
    //                 color: "black",
    //                 fontWeight: "500",
    //                 marginBottom: "0px",
    //                 fontSize: "13px",
    //               }}
    //             >
    //               {" "}
    //               Sales Order{" "}
    //             </p>
    //           </div>
    //         </div>

    //         <div
    //           className={`menuItem ${menuSelection == 1 ? "active" : ""}`}
    //           style={{ width: "100%", height: "2.5rem" }}
    //           onClick={() => changeMenu(1)}
    //         >
    //           <div
    //             className="  d-flex justify-content-start align-items-center ms-4"
    //             style={{ height: "100%", gap: "10px" }}
    //           >
    //             <img
    //               src={billImg}
    //               alt="leo"
    //               style={{ width: "1.2rem", height: "1.2rem" }}
    //             />
    //             <p
    //               style={{
    //                 color: "black",
    //                 fontWeight: "500",
    //                 marginBottom: "0px",
    //                 fontSize: "13px",
    //               }}
    //             >
    //               {" "}
    //               Sales Bill
    //             </p>
    //           </div>
    //         </div>

    //         <div
    //           className={`menuItem ${menuSelection == 2 ? "active" : ""}`}
    //           style={{ width: "100%", height: "2.5rem" }}
    //           onClick={() => changeMenu(2)}
    //         >
    //           <div
    //             className=" d-flex justify-content-start align-items-center ms-4"
    //             style={{ height: "100%", gap: "10px" }}
    //           >
    //             <img
    //               src={ackImg}
    //               alt="leo"
    //               style={{ width: "1.2rem", height: "1.2rem" }}
    //             />
    //             <p
    //               style={{
    //                 color: "black",
    //                 fontWeight: "500",
    //                 marginBottom: "0px",
    //                 fontSize: "13px",
    //               }}
    //             >
    //               {" "}
    //               Sales Acknowledgement
    //             </p>
    //           </div>
    //         </div>
    //         <div
    //           className={`menuItem ${menuSelection == 3 ? "active" : ""}`}
    //           style={{ width: "100%", height: "2.5rem" }}
    //           onClick={() => changeMenu(3)}
    //         >
    //           <div
    //             className="  d-flex justify-content-start align-items-center ms-4"
    //             style={{ height: "100%", gap: "10px" }}
    //           >
    //             <img
    //               src={cancelation}
    //               alt="leo"
    //               style={{ width: "1.2rem", height: "1.2rem" }}
    //             />
    //             <p
    //               style={{
    //                 color: "black",
    //                 fontWeight: "500",
    //                 marginBottom: "0px",
    //                 fontSize: "13px",
    //               }}
    //             >
    //               {" "}
    //               Sales Order Cancelation
    //             </p>
    //           </div>
    //         </div>
    //         <div
    //           className={`menuItem ${menuSelection == 4 ? "active" : ""}`}
    //           style={{ width: "100%", height: "2.5rem" }}
    //           onClick={() => changeMenu(4)}
    //         >
    //           <div
    //             className="  d-flex justify-content-start align-items-center ms-4"
    //             style={{ height: "100%", gap: "10px" }}
    //           >
    //             <img
    //               src={returnImg}
    //               alt="leo"
    //               style={{ width: "1.2rem", height: "1.2rem" }}
    //             />
    //             <p
    //               style={{
    //                 color: "black",
    //                 fontWeight: "500",
    //                 marginBottom: "0px",
    //                 fontSize: "13px",
    //               }}
    //             >
    //               {" "}
    //               Sales return
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     {menuSelection === 0 ? (
    //       <div className="" style={{ marginLeft: "10px", width: "100%" }}>
    //         <SalesOrder />
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //     {menuSelection === 1 ? (
    //       <div className="" style={{ marginLeft: "10px", width: "100%" }}>
    //         <SalesBill />
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //     {menuSelection === 2 ? (
    //       <div className="" style={{ marginLeft: "10px", width: "100%" }}>
    //         <SalesAcknowledgement />
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //     {menuSelection === 3 ? (
    //       <div className="" style={{ marginLeft: "10px", width: "100%" }}>
    //         <SalesOrderCancelation />
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //     {menuSelection === 4 ? (
    //       <div className="" style={{ marginLeft: "10px", width: "100%" }}>
    //         <SalesReturn />
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //   </div>
    // </div>
    <div>
      <div className=" purchaseContainer"></div>
      <div className="d-flex mt-2" style={{ color: "black" }}>
        <div style={{ position: "relative" }}>
          <div
            className={` backImgDiv ${
              toggleSideBar ? "backImgDivOpen" : "backImgDivClose"
            } `}
            style={{ transition: "all .5s" }}
            onClick={() => {
              setToggleSideBar(!toggleSideBar);
            }}
          >
            <img src={backimg} alt="" />
          </div>
          <div
            className={`scrollable-div ${
              toggleSideBar ? "sideBarOff" : "sideBarOn"
            } `}
            style={{ transition: "width .5s" }}
          >
            <h6 className=" ms-3 mt-2" style={{ fontSize: "13px" }}>
              <b>Sales</b>
            </h6>
            <hr className="" style={{ margin: ".5rem" }} />

            <div
              className={`menuItem ${menuSelection == 0 ? "active" : ""}`}
              style={{
                width: "100%",
                height: "2.5rem",
                transition: "background-color 0.3s",
              }}
              onClick={() => changeMenu(0)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={orderImg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Sales Order{" "}
                </p>
              </div>
            </div>

            <div
              className={`menuItem ${menuSelection == 1 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(1)}
            >
              <div
                className="  d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={billImg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Sales Acknowledgement
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 2 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(2)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={ackImg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Sales Bill
                </p>
              </div>
            </div>

            <div
              className={`menuItem ${menuSelection == 5 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(5)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={cancelation}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Sales Order Cancelation{" "}
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 6 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(6)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={cancelation}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Sales Order Return{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        {menuSelection === 0 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <SalesOrder />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 2 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <SalesBill />
          </div>
        ) : (
          ""
        )}

        {menuSelection === 1 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <SalesAcknowledgement />
          </div>
        ) : (
          ""
        )}

        {menuSelection === 5 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <SalesBillCancelation />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 6 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <SalesReturn />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Sales;
